import Layout from '../components/Layout';
import Title from '../components/Title';

export default function Pictures() {
  const pictures = Array.from(Array(21).keys());

  return (
    <Layout>
      <div className="text-center">
        <Title>
          Profile pictures
        </Title>
      </div>

      <div className="grid sm:grid-cols-3 xl:grid-cols-4">
        {pictures.map(i => (
          <div className="p-2" key={i}>
            <img src={`/img/pfp/${i+1}.png`} alt="" />
          </div>
        ))}
      </div>
    </Layout>
  )
}
