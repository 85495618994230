import React from 'react'
import TopLeftElement from '../assets/trusted-by/top-left.svg'
import TopRightElement from '../assets/trusted-by/top-right.svg'
import BottomRightElement from '../assets/trusted-by/bottom-right.svg'
import BottomLeftElement from '../assets/trusted-by/bottom-left.svg'
import Ascendex from '../assets/trusted-by/ascendex.svg'
import Poloniex from '../assets/trusted-by/poloniex.svg'
import constants from '../constants'

export const TrustedBy = () => {
    const {TrustedBy: {poloniex, ascendex}} = constants;
  return (
    <div className='pb-48 pt-20 md:pb-60 md:pt-48 relative trusted-by-bg overflow-hidden'>
        <div className={'max-w-3xl relative mx-auto px-4 sm:px-6 lg:px-8'}>
            <div className='relative z-[5]'>
                <h3 className='f-pp text-[#1D1D1D] text-[70px] sm:text-[100px] md:text-[130px] uppercase leading-[1] text-center'>
                    trusted <br/>
                    by <span className='text-red'> the best</span>
                </h3>
            </div>

            {/* Elements */}
            <img className='scale-[0.6] md:scale-100 pointer-events-none absolute top-[-90px] left-[50px]' alt='element' src={TopLeftElement} />
             <img className='scale-[0.6] md:scale-100 pointer-events-none absolute bottom-[-130px] left-[-110px]' alt='element' src={BottomLeftElement} />
             <img className='scale-[0.6] md:scale-100 pointer-events-none absolute bottom-[-145px] z-30 right-[-45px]' alt='element' src={BottomRightElement} />

            {/* Buttons */}
             <div  className='absolute scale-[0.7] md:scale-100 flex z-10 top-[-100px] right-[-70px]'>
                <a href={ascendex} rel='noopener noreferer' target='_blank' className='flex mr-[-20px] mt-[-90px] z-10'>
                    <img  alt='element' src={Ascendex} />
                </a>
                <img className='pointer-events-none' alt='element' src={TopRightElement} />
            </div>
            <a href={poloniex} target='_blank' className='absolute top-[78%] left-[230px] z-30 flex'>
                <img  alt='element' src={Poloniex} />
            </a>
        </div>
    </div>
  )
}
