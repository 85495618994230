import { useEffect } from "react";
import constants from "../constants";
import { ProgressBar } from "./ProgressBar";

export default function Hero() {
  useEffect(() => {
    if (!window) return;
    window.Jupiter.init({
      displayMode: "integrated",

      integratedTargetId: "jupiter",
      // endpoint: "https://api.mainnet-beta.solana.com",
      endpoint:
        "https://mainnet.helius-rpc.com/?api-key=40d8e2bc-c163-490f-a95d-94e7309fdf46",
      formProps: {
        fixedOutputMint: true,
        initialOutputMint: "wo1zgt8rfrYpvdVi4nidoj1SYfcR4pQx69bmNv2JLhQ",
        initialInputMint: "So11111111111111111111111111111111111111112",
      },
    });
  }, []);
  return (
    <div
      style={
        {
          // gridTemplateColumns: constants.withProgressBar ? '1.3fr 1fr' : '1fr 1fr',
        }
      }
      className={`pt-6 grid md:grid-cols-[1fr,1fr] gap-10 items-center max-w-7xl mx-auto px-4 sm:px-6 lg:px-8`}
    >
      <div className="">
        <img
          className="max-w-[350px] mt-0 w-full h-auto mx-auto"
          src="/img/hero-egg.png"
        />
        <div className=" flex justify-center items-center mt-8 px-4  space-x-2 md:space-x-3.5 mx-auto">
          {constants.socialLinks.map((link, index) => (
            <a
              key={index}
              href={link.href}
              className={`${
                !link.href ? "opacity-40 pointer-events-none" : ""
              }`}
              target="_blank"
              rel="noreferrer"
            >
              <img
                className={[
                  "!w-6 !h-6 transform hover:scale-105 transition",
                  link.customClass,
                ].join(" ")}
                src={link.icon}
                alt=""
              />
            </a>
          ))}
        </div>
      </div>

      {/*{constants.withProgressBar && (*/}
      {/*    <div>*/}
      {/*      <ProgressBar/>*/}
      {/*      <p className='f-pp text-center text-pp mt-8 text-xl tracking-wide'>*/}
      {/*        PRESALE CLOSED*/}
      {/*      </p>*/}
      {/*    </div>*/}
      {/*)}*/}

      {!constants.withProgressBar && (
        <div
          id="jupiter"
          className="flex justify-center bg-gray-700 rounded-xl p-4 max-w-[557px] w-full mx-auto"
        >
          {/* <Uniswap /> */}
        </div>
      )}
    </div>
  );
}
