import Title from './Title';
import constants from '../constants';
import TitleSmall from './TitleSmall';

export default function HowToBuy() {
    return (
        <div className={'max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 pb-12 md:pb-24  '}>
            <Title customClass="text-center  font-cocogose-cb text-6xl mb-12">
                {constants.howToBuy.title}
            </Title>
            <div id="how-to-buy" className="bg-white border border-[#DEDDD6] px-8 rounded-[40px]">
                {/*<div>*/}
                {/*    <img className="w-full h-auto rounded-lg" src="/img/howtobuy.gif" alt=""/>*/}
                {/*</div>*/}

                <div>


                    <div className=" ">
                        {constants.howToBuy.steps.map((step, index) => (
                            <div className={`grid relative sm:grid-cols-[minmax(0,120px),auto] gap-8 items-center`}>
                                <div className={`hidden sm:flex items-center justify-center `}>
                                    <div
                                        className={' z-10 relative f-me-bold text-xl bg-[#FCA160] rounded-full w-[48px] h-[48px] text-center flex items-center justify-center text-white'}>
                                        {index + 1}
                                    </div>
                                    {/*     Side Line  */}
                                    <div style={{
                                        display: index === constants.howToBuy.steps.length - 1 ? 'none' : '',
                                        height: index === constants.howToBuy.steps.length - 2 ? '80%' : ''
                                    }} className={'h-[120%] w-[2px] bg-amber-500 absolute top-1/2 left-[58.5px]'}/>
                                </div>
                                <div
                                    style={{
                                        borderBottom: index === constants.howToBuy.steps.length - 1 ? '0' : ''
                                    }}
                                    className="break-words break-all py-6 sm:py-[36px] border-b border-b-[#DEDDD6]   items-center grid gap-4 sm:gap-0 sm:grid-cols-[minmax(0,260px),auto]">
                                    <TitleSmall customClass="text-left font-gt-cn text-2xl">
                                        {step.title}
                                    </TitleSmall>
                                    <p className="text-lg  font-gt leading-[auto] text-left">
                                        {step.content}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>

                    {/* <div className="mt-3 flex flex-col sm:flex-row text-center justify-center sm:justify-start gap-3">
          <a
            className="cursor-not-allowed relative hover-btn bg-od text-white uppercase overflow-hidden f-nh-black text-lg rounded py-2.5 px-6 sm:px-10"
            // href={constants.navbar.buyWithCard.href}
            // target="_blank"
          >
            {constants.navbar.buyWithCard.title}
          </a>

          <a
            className="relative hover-btn bg-od text-white uppercase overflow-hidden f-nh-black text-lg rounded py-2.5 px-6 sm:px-10"
            href={constants.howToBuy.buyNowLink}
            target="_blank"
          >
            Buy on Solana
          </a>
        </div> */}
                </div>


            </div>

            <div className={'flex items-center justify-center mt-8 gap-4'}>
                <button
                    // href={constants.navbar.buyWithCard.href}
                    // target="_blank"
                    disabled
                    className="disabled:bg-[#FFFFFF] text-[17px] disabled:text-[#DEDDD6] disabled:border-[#DEDDD6] cursor-not-allowed hidden lg:block f-nh-black text-xs sm:text-sm bg-ol border-2 border-od rounded py-2.5 px-2 sm:px-6 hover:bg-od hover:text-white transition"
                    rel="noreferrer"
                >
                    {constants.navbar.buyWithCard.title}
                </button>

                <a
                    href={constants.howToBuy.buyNowLink}
                    target="_blank"
                    className="f-nh-black text-xs sm:text-sm text-white text-[17px]  bg-red border-2 border-red rounded py-2.5 px-2 sm:px-6 hover:bg-red hover:text-white transition"
                    rel="noreferrer"
                >
                    {constants.navbar.buyNowLabel}
                </a>
            </div>
        </div>
    )
}
