import constants from '../constants';
import TitleSmall from './TitleSmall';
import Sound from './Sound';
import {Link} from 'react-router-dom';
import {ChevronDownIcon} from '@heroicons/react/24/solid'
import {useRef, useState} from 'react';
import {useClickOutside} from '../libs/hooks/useClickOutside';
import {CopyContract} from "./CopyContract";
import { FaRegFaceSmile } from "react-icons/fa6";

function NavbarLogo() {
    return (
        <svg className="navbar-logo max-w-[149px] sm:h-12 w-full h-auto" viewBox="0 0 163 48" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path d="M164.8 28V25.12H167.6V28H164.8Z" fill="black"/>
            <path
                d="M155.54 20.5H162.06V28H159.64V26.4C159.307 26.9733 158.787 27.4467 158.08 27.82C157.373 28.18 156.527 28.36 155.54 28.36C153.633 28.36 152.08 27.68 150.88 26.32C149.693 24.9467 149.1 23.2133 149.1 21.12C149.073 19.0267 149.673 17.26 150.9 15.82C152.127 14.3667 153.813 13.6533 155.96 13.68C157.64 13.68 159.033 14.12 160.14 15C161.247 15.8533 161.887 17.0733 162.06 18.66H159.4C159.32 17.9 158.953 17.2867 158.3 16.82C157.647 16.3533 156.84 16.12 155.88 16.12C154.52 16.12 153.48 16.5867 152.76 17.52C152.053 18.4533 151.7 19.6267 151.7 21.04C151.7 22.4933 152.073 23.6733 152.82 24.58C153.553 25.4733 154.593 25.92 155.94 25.92C156.953 25.92 157.78 25.6667 158.42 25.16C159.06 24.64 159.44 23.8933 159.56 22.92H155.54V20.5Z"
                fill="black"/>
            <path
                d="M140.54 20.5H147.06V28H144.64V26.4C144.307 26.9733 143.787 27.4467 143.08 27.82C142.373 28.18 141.527 28.36 140.54 28.36C138.633 28.36 137.08 27.68 135.88 26.32C134.693 24.9467 134.1 23.2133 134.1 21.12C134.073 19.0267 134.673 17.26 135.9 15.82C137.127 14.3667 138.813 13.6533 140.96 13.68C142.64 13.68 144.033 14.12 145.14 15C146.247 15.8533 146.887 17.0733 147.06 18.66H144.4C144.32 17.9 143.953 17.2867 143.3 16.82C142.647 16.3533 141.84 16.12 140.88 16.12C139.52 16.12 138.48 16.5867 137.76 17.52C137.053 18.4533 136.7 19.6267 136.7 21.04C136.7 22.4933 137.073 23.6733 137.82 24.58C138.553 25.4733 139.593 25.92 140.94 25.92C141.953 25.92 142.78 25.6667 143.42 25.16C144.06 24.64 144.44 23.8933 144.56 22.92H140.54V20.5Z"
                fill="black"/>
            <path d="M131.58 28H121.8V14H131.2V16.44H124.4V19.7H130.66V22.14H124.4V25.56H131.58V28Z" fill="black"/>
            <path d="M117.36 14V28H114.58L108.36 18.12V28H105.8V14H108.8L114.8 23.62V14H117.36Z" fill="black"/>
            <path
                d="M101.6 28L100.44 24.9H94.52L93.36 28H90.6L96.04 14H99L104.44 28H101.6ZM95.42 22.46H99.54L97.48 16.94L95.42 22.46Z"
                fill="black"/>
            <path d="M91.02 14V16.44H86.42V28H83.84V16.44H79.3V14H91.02Z" fill="black"/>
            <path
                d="M72.76 28.36C71.16 28.36 69.8 27.9067 68.68 27C67.56 26.0933 67 24.84 67 23.24H69.6C69.64 24.1733 69.9533 24.8933 70.54 25.4C71.1267 25.8933 71.86 26.14 72.74 26.14C73.46 26.14 74.0667 25.9667 74.56 25.62C75.0533 25.26 75.3 24.8 75.3 24.24C75.3 23.8 75.1533 23.4533 74.86 23.2C74.5933 22.9467 74.26 22.76 73.86 22.64C73.42 22.5067 72.8267 22.3533 72.08 22.18C71.2133 21.9667 70.44 21.7267 69.76 21.46C68.1733 20.7933 67.38 19.6267 67.38 17.96C67.38 16.6133 67.8733 15.56 68.86 14.8C69.8467 14.04 71.0867 13.66 72.58 13.66C74.0733 13.66 75.3 14.04 76.26 14.8C77.2333 15.56 77.72 16.6667 77.72 18.12H75.1C75.02 16.6667 74.1533 15.94 72.5 15.94C71.7 15.94 71.0733 16.1 70.62 16.42C70.1667 16.7267 69.94 17.1733 69.94 17.76C69.98 18.3867 70.2133 18.8333 70.64 19.1C71.08 19.3667 71.8267 19.62 72.88 19.86C73.8 20.06 74.62 20.2867 75.34 20.54C75.98 20.7667 76.56 21.16 77.08 21.72C77.6267 22.3067 77.9 23.0933 77.9 24.08C77.9 25.4 77.4067 26.4467 76.42 27.22C75.4467 27.98 74.2267 28.36 72.76 28.36Z"
                fill="black"/>
            <path
                d="M62.32 14H64.88V22.64C64.88 24.48 64.38 25.8933 63.38 26.88C62.3933 27.8667 61.02 28.36 59.26 28.36C57.5 28.36 56.1133 27.8667 55.1 26.88C54.1 25.8933 53.6 24.48 53.6 22.64V14H56.18V22.52C56.18 23.5867 56.46 24.4133 57.02 25C57.58 25.5867 58.3533 25.88 59.34 25.88C60.2867 25.88 61.02 25.6133 61.54 25.08C62.06 24.5467 62.32 23.7733 62.32 22.76V14Z"
                fill="black"/>
            <path
                d="M50.1 14V23.68C50.1 25.2133 49.6933 26.38 48.88 27.18C48.0667 27.9667 47.0333 28.36 45.78 28.36C44.5133 28.36 43.48 27.98 42.68 27.22C41.8933 26.46 41.5 25.38 41.5 23.98V22.84H44.1V24.06C44.1 25.26 44.6667 25.86 45.8 25.86C46.9333 25.86 47.5 25.2267 47.5 23.96V14H50.1Z"
                fill="black"/>
            <path
                d="M32.1262 24.963C32.1262 33.4516 25.2445 40.3332 16.756 40.3332C8.26743 40.3332 1.3858 33.4516 1.3858 24.963C1.3858 13.5285 8.26743 1.38574 16.756 1.38574C25.2445 1.38574 32.1262 13.4241 32.1262 24.963Z"
                fill="#EEA463"/>
            <path
                d="M32.1262 24.963C32.1262 33.4516 25.2446 40.3332 16.756 40.3332C9.90619 40.3332 4.10266 35.8529 2.1167 29.6612C4.59063 33.6763 9.02783 36.3522 14.0892 36.3522C21.8514 36.3522 28.1452 30.0584 28.1452 22.2962C28.1452 13.2901 23.5582 3.9482 17.387 1.40845C25.5828 1.98494 32.1262 13.7123 32.1262 24.963Z"
                fill="#DA8A44"/>
            <path
                d="M16.756 40.7191C14.6293 40.7191 12.5662 40.3015 10.6234 39.4799C8.74637 38.6855 7.06228 37.5507 5.61423 36.1026C4.16618 34.6546 3.03135 32.9705 2.23697 31.0935C1.41762 29.1529 1 27.0898 1 24.9631C1 22.1124 1.41081 19.2141 2.22335 16.3497C3.00865 13.5785 4.16164 10.9275 5.56203 8.68282C7.02596 6.33371 8.70551 4.47032 10.5508 3.14937C12.539 1.72175 14.6271 1 16.756 1C18.8872 1 20.9753 1.71721 22.9636 3.13349C24.8111 4.44989 26.4906 6.30194 27.9523 8.64423C29.3527 10.8844 30.5057 13.5354 31.291 16.3066C32.1012 19.1755 32.5143 22.0875 32.5143 24.9631C32.5143 27.0898 32.0967 29.1529 31.2751 31.0958C30.4807 32.9728 29.3459 34.6569 27.8978 36.1049C26.4498 37.553 24.7657 38.6878 22.8887 39.4822C20.9458 40.3015 18.8827 40.7191 16.756 40.7191ZM16.756 1.77169C14.7905 1.77169 12.8545 2.44578 10.9979 3.7758C9.23435 5.03774 7.62515 6.82623 6.21569 9.09136C4.85389 11.277 3.73041 13.8599 2.96553 16.5608C2.18477 19.3162 1.77169 22.2214 1.77169 24.9631C1.77169 26.9854 2.16888 28.9487 2.94964 30.7962C3.70544 32.5801 4.7858 34.1825 6.16122 35.5602C7.53664 36.9379 9.13902 38.016 10.9252 38.7718C12.7728 39.5525 14.7337 39.9497 16.7583 39.9497C18.7828 39.9497 20.7438 39.5525 22.5913 38.7718C24.3753 38.016 25.9777 36.9356 27.3554 35.5602C28.7308 34.1848 29.8111 32.5824 30.5669 30.7962C31.3477 28.9487 31.7449 26.9877 31.7449 24.9631C31.7449 22.1601 31.3432 19.3185 30.551 16.5177C29.7862 13.8145 28.6627 11.2339 27.3009 9.05277C25.8892 6.79673 24.28 5.01731 22.5164 3.76218C20.6598 2.44124 18.7238 1.77169 16.756 1.77169Z"
                fill="#202228" stroke="black"/>
            <path
                d="M12.1123 10.5349C9.81538 13.6057 8.30833 16.3589 6.95561 15.3466C5.60288 14.3343 6.36776 11.0251 8.66466 7.95428C10.9593 4.88569 13.9189 3.21749 15.2717 4.22749C16.6244 5.23976 14.4092 7.46403 12.1123 10.5349Z"
                fill="#FFE4CA"/>
            <path
                d="M6.11587 20.4012C5.63016 22.0535 5.42589 23.4493 4.69732 23.236C3.96876 23.0226 3.48532 21.3567 3.97103 19.7044C4.45674 18.052 5.72775 17.0375 6.45631 17.2509C7.18488 17.4642 6.60157 18.7466 6.11587 20.4012Z"
                fill="#FFE4CA"/>
            <path opacity="0.25"
                  d="M17.1803 47.9999C24.4144 47.9999 30.2787 46.965 30.2787 45.6884C30.2787 44.4118 24.4144 43.377 17.1803 43.377C9.94631 43.377 4.08197 44.4118 4.08197 45.6884C4.08197 46.965 9.94631 47.9999 17.1803 47.9999Z"
                  fill="#1D1D1D"/>
        </svg>
    )
}

function NavbarLink({link, hasMoreLinks}) {
    const {title, href, links} = link;
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const elementRef = useRef();

    const toggleDropdown = () => {
        if (!hasMoreLinks) return;
        setIsDropdownOpen(val => !val);
    }

    const onClickOutside = () => {
        setIsDropdownOpen(false);
    }

    useClickOutside(elementRef, onClickOutside);

    return (

        <a
            ref={elementRef}
            onClick={toggleDropdown}
            href={`/${href}`}
            className="group py-2 px-3 hover:bg-od rounded-full transition relative"
        >
            <TitleSmall
                customClass="text-[17px] non-italic font-normal group-hover:text-white transition leading-none flex items-center gap-4">
                {title} {hasMoreLinks && (
                <ChevronDownIcon className="h-4 w-4 text-black transition group-hover:text-white"/>
            )}
            </TitleSmall>

            {/* dropdown */}
            {isDropdownOpen && (
                <div
                    className='absolute z-[9999] top-8 left-0 bg-white p-4 shadow-lg border border-[rgba(92,102,132,0.24)] rounded-md flex flex-col gap-2'>
                    {links && links?.map(val => (
                        <a href={val?.href}
                           className="py-1 px-2 rounded-full transition relative text-gray-300 cursor-not-allowed">
                            {val.title}
                        </a>
                    ))}
                </div>
            )}

        </a>
    )
}

export default function Navbar() {
    return (
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between">
                <div className=" -mb-2.5 flex gap-4 ">
                    <Link to='/'>
                        <NavbarLogo/>
                    </Link>
                    <div className="hidden lg:block mt-1">
                        <div className="flex space-x-3">
                            {constants.navbar.links.map((link, index) => (
                                <>
                                    <NavbarLink
                                        key={index}
                                        // title={link.title}
                                        // href={link.href}
                                        link={link}
                                        hasMoreLinks={link?.links?.length > 0}

                                    />

                                </>
                            ))}
                        </div>

                    </div>
                </div>

                <div className='hidden lg:flex items-start mt-[6px]'>
                    <a
                        rel="noreferrer" target='_blank'
                        href={`https://drive.google.com/drive/folders/1UNoBlbOesqOEyFvYm0LeGxBeMUtpauPY?usp=sharing`}
                        className="group py-2 px-3 hover:bg-od rounded-full transition relative"
                    >
                        <TitleSmall
                            customClass="uppercase group-hover:text-white transition leading-none flex items-center gap-4">
                            BRAND
                        </TitleSmall>
                    </a>
                </div>

                <div>
                    <div className="flex flex-col justify-center space-y-5">
                        {/* sm:justify-around should be replaced when uncomment buttons */}
                        <div
                            className="flex flex-wrap justify-end sm:justify-around items-center gap-3 sm:gap-0 sm:space-x-4">


                            <a href="/pfp" target="_blank">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M18.8271 5.91461C18.7814 6.12447 18.7449 6.33432 18.6948 6.54418C18.526 7.21025 18.2066 7.7942 17.7276 8.28234C16.619 9.40462 15.515 10.5315 14.3836 11.6264C12.6637 13.2915 10.0541 13.1592 8.50759 11.3526C8.07875 10.8508 7.80046 10.2669 7.66816 9.6236C7.54498 9.02596 7.90995 8.46939 8.49846 8.33252C9.08241 8.19566 9.64811 8.55607 9.80322 9.16739C9.9629 9.80608 10.3324 10.2623 10.9483 10.4858C11.6737 10.7459 12.3306 10.5862 12.8781 10.0433C13.6901 9.24038 14.4976 8.42833 15.3051 7.61627C15.5834 7.33799 15.8663 7.06882 16.1354 6.78141C16.619 6.26589 16.7696 5.65457 16.5597 4.97938C16.3453 4.30875 15.8708 3.88904 15.1774 3.75217C14.6026 3.63812 14.0779 3.77955 13.6399 4.17645C13.2659 4.51861 12.9191 4.88814 12.545 5.2303C11.9383 5.78687 10.9939 5.54052 10.7521 4.76496C10.629 4.37262 10.702 4.00765 10.9848 3.72024C11.4 3.2914 11.8151 2.85344 12.2759 2.47479C13.4848 1.48481 14.858 1.25214 16.3088 1.82697C17.755 2.40179 18.5716 3.51038 18.7997 5.04781C18.8042 5.08431 18.8179 5.12537 18.8316 5.16186C18.8271 5.41278 18.8271 5.66369 18.8271 5.91461Z"
                                        fill="#F6A45E"/>
                                    <path
                                        d="M8.45749 5.28954C10.5697 5.29411 12.2532 6.68555 12.5497 8.4967C12.6455 9.08065 12.2805 9.61898 11.7011 9.74672C11.1354 9.86989 10.5697 9.5323 10.4283 8.96203C10.3325 8.58338 10.182 8.24122 9.89455 7.97206C9.19655 7.31055 8.10165 7.28774 7.40821 7.9675C6.26312 9.08521 5.14541 10.2257 4.01401 11.3571C3.96382 11.4073 3.92276 11.4575 3.8817 11.5122C3.32969 12.265 3.43006 13.3052 4.11437 13.9302C4.78956 14.546 5.84341 14.5597 6.52772 13.9347C6.90182 13.5926 7.23485 13.2094 7.59525 12.8581C8.13814 12.3243 9.02775 12.4794 9.3471 13.1592C9.54783 13.588 9.49765 14.0032 9.1783 14.3499C8.80877 14.7605 8.43468 15.1711 8.02409 15.5315C6.84707 16.5716 5.483 16.8636 4.00488 16.3435C2.5222 15.828 1.64171 14.7377 1.3908 13.1911C1.19919 12.0004 1.48204 10.8872 2.30778 10.0068C3.51217 8.72481 4.76219 7.48848 6.03502 6.27496C6.75127 5.58152 7.65912 5.29411 8.45749 5.28954Z"
                                        fill="#F6A45E"/>
                                </svg>
                                {/*<FaRegFaceSmile color={'#F69B4C'} />*/}

                            </a>

                            <Sound/>
                            {/* <div className="hidden sm:flex   space-x-2.5 mx-auto">
              {constants.socialLinks.map((link, index) => (
                <a key={index} href={link.href} className={!link.href ? 'opacity-40 pointer-events-none' : ''} target="_blank" rel="noreferrer">
                  <img className={['h-5 transform hover:scale-105 transition grayscale filter brightness-0', link.customClass].join(' ')} src={link.icon} alt="" />
                </a>
              ))}
            </div> */}

                            {/* convert this to a as needed to add href  */}
                            <button
                                // href={constants.navbar.buyWithCard.href}
                                // target="_blank"
                                disabled
                                className="disabled:bg-[#F7F4EF] text-[17px] disabled:text-[#D4D1CC] disabled:border-[#F7F4EF] cursor-not-allowed hidden lg:block f-nh-black text-xs sm:text-sm bg-ol border-2 border-od rounded py-2.5 px-2 sm:px-6 hover:bg-od hover:text-white transition"
                                rel="noreferrer"
                            >
                                {constants.navbar.buyWithCard.title}
                            </button>

                            <a
                                href={constants.howToBuy.buyNowLink}
                                target="_blank"
                                className="f-nh-black text-xs sm:text-sm text-white text-[17px] bg-[#F69B4C] border-2 border-od rounded py-2.5 px-2 sm:px-6 hover:bg-od hover:text-white transition"
                                rel="noreferrer"
                            >
                                {constants.navbar.buyNowLabel}
                            </a>

                            {/* <a
              // href={constants.navbar.buyWithCard.href}
              // target="_blank"
              className="cursor-not-allowed sm:hidden f-nh-black text-xs sm:text-sm bg-ol border-2 border-od rounded py-2.5 px-2 sm:px-6 hover:bg-od hover:text-white transition" rel="noreferrer"
            >
              {constants.navbar.buyWithCard.title}
            </a> */}
                        </div>

                        {/*<div className="hidden sm:flex pl-12 space-x-2.5 mx-auto">*/}
                        {/*    {constants.socialLinks.map((link, index) => (*/}
                        {/*        <a key={index} href={link.href}*/}
                        {/*           className={!link.href ? 'opacity-40 pointer-events-none' : ''}*/}
                        {/*           target="_blank" rel="noreferrer">*/}
                        {/*            <img*/}
                        {/*                className={['h-5 transform hover:scale-105 transition grayscale filter brightness-0', link.customClass].join(' ')}*/}
                        {/*                src={link.icon} alt=""/>*/}
                        {/*        </a>*/}
                        {/*    ))}*/}
                        {/*</div>*/}
                    </div>
                </div>

            </div>
            <p className={`mt-3 text-center flex items-start justify-end gap-2`}>
                <CopyContract shouldOnlyCopy={true} contract={'wo1zgt8rfrYpvdVi4nidoj1SYfcR4pQx69bmNv2JLhQ'}/>
            </p>
        </div>
    )
}
