import image1 from '../assets/gallery/1.png'
import image2 from '../assets/gallery/2.png'
import image3 from '../assets/gallery/3.jpeg'
import image4 from '../assets/gallery/4.jpeg'
import image5 from '../assets/gallery/5.jpeg'
import image6 from '../assets/gallery/6.jpeg'
import image7 from '../assets/gallery/7.jpeg'
import image8 from '../assets/gallery/8.jpeg'
import image9 from '../assets/gallery/9.jpeg'
import Marquee from "react-fast-marquee";
import Title from './Title'
import constants from '../constants'

export const ImagesGallery = () => {
    const images = [image1, image2, image3, image4, image5, image6, image7, image8, image9];
    const imagesToRender = [...images];

  return (
    <div className={'py-24 bg-[#FAF7EE]'}>
    <div className='text-center  text-fpp '>
        <Title customClass=' text-center font-cocogose-cb text-[60px] tracking-wide text-[#1D1D1D]'>
           {constants.imageGallery.blackText} {" "} <span className={`text-[#E75346]`}>{constants.imageGallery.redText}</span>
        </Title>
    </div>
      <Marquee direction='right' className='!mt-6'>
            {imagesToRender.map(val => (
                <img className='ml-6 h-[300px] w-[300px] object-cover slider-image' src={val} alt="img 1" />
                ))}
      </Marquee>
    </div>
  )
}
