import React, { useEffect } from "react";
import { FaCopy } from "react-icons/fa";
import useCopyToClipboard from "../libs/hooks/useCopyToClipboard";
import toast, { Toaster } from 'react-hot-toast';


export const CopyContract = ({contract, shouldOnlyCopy}) => {
  const [copyToClipboard, { success }] = useCopyToClipboard();

  return (
    <span onClick={() => {
        copyToClipboard(contract);
        toast.success('Copied')
    }} className="block gap-4 break-all break-words cursor-pointer">
        {!shouldOnlyCopy && (
            <a target={'_blank'} href="https://jup.ag/swap/SOL-EGG_wo1zgt8rfrYpvdVi4nidoj1SYfcR4pQx69bmNv2JLhQ"
               rel={'noopener noreferrer'}
               className={'no-underline'}
            >
                {contract}
            </a>
        )}
        {shouldOnlyCopy && (
            contract
        )}

        <FaCopy className="inline-block ml-3 -mt-1 cursor-pointer" color="#F69B4C" size={18}/>
    </span>
  );
};
