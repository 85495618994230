import Title from './Title';
import constants from '../constants';

export default function About() {
    return (
        <div className={` `}>
            <div className={'max-w-7xl mx-auto px-4 sm:px-6 lg:px-8'}>
                <div id="about" className="  p-5 ">
                    <div className="grid md:grid-cols-2">
                        <div className="flex justify-center items-center">
                            <img className="w-full h-auto" src="/img/about.gif" alt=""/>
                        </div>

                        <div className="flex items-center">
                            <div>
                                <Title customClass="text-center md:text-left !text-4xl sm:!text-6xl ">
                                    <span className={'text-red'}>
                                    {constants.about.title.textRed}
                                    </span> <br/>
                                    <span >
                                    {constants.about.title.textBlack}
                                    </span>
                                </Title>

                                <p className="mt-10 text-sm">
                                    {constants.about.content}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
