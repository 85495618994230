import Layout from '../components/Layout';
import HowToBuy from '../components/HowToBuy';
import About from '../components/About';
import Roadmap from '../components/Roadmap';
import SubHero from '../components/Tokenomics';
import Hero from '../components/Hero';
import {ImagesGallery} from '../components/ImagesGallery';
import {Headlines} from "../components/Headlines";
import { TrustedBy } from '../components/TrustedBy';
import BgTrustedBy from '../assets/bg-trusted-by.svg';

export default function Welcome() {
    return (
        <Layout>

            <Hero/>
            <Headlines/>

            <div className="">
                <ImagesGallery/>
                <div className={`m-0`}>
                    <SubHero/>
                </div>


                <div className={`bg-bt`}>
                    <About/>

                    <Roadmap/>

                    <div className={'mt-16 relative z-10'}>
                        <HowToBuy/>
                    </div>
                        
                    <div className='relative'>
                        <div className='relative z-[5]'>
                            <TrustedBy/>
                        </div>
                        <img className='absolute bottom-[150px] left-0 w-full h-auto' src={BgTrustedBy} alt='bg' />
                    </div>
                </div>


            </div>
        </Layout>
    )
}
