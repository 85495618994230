import constants from '../constants';

export default function Roadmap() {
    return (
        <div className={`pb-28 md:pb-16`}>

        <div className={'max-w-7xl mx-auto px-4 sm:px-6 lg:px-8'}>
            <div
                id="roadmap"
                // className="bg-od px-8 pt-8 pb-16"
                className=" px-8 pt-8 pb-20"
                style={{
                    // backgroundImage: 'url(/img/roadmap.png)',
                    // backgroundRepeat: 'no-repeat',
                    // backgroundSize: '100%',
                    // backgroundPosition: 'center'
                }}
            >
                {/* <div className="sm:pb-40 grid sm:grid-cols-2 sm:gap-5"> */}
                <div className="sm:pb-0 sm:gap-5 text-od max-w-[529px] text-center mx-auto uppercase">
                    <div className=" relative">
                        <h3 className=" text-4xl md:text-6xl f-pp">
                            {constants.roadmap.title}
                        </h3>

                        <img className="w-full max-w-[247px] mx-auto absolute -bottom-[250px] md:-bottom-28 right-1/2 translate-x-1/2 md:translate-x-0 md:-right-[10%] lg:-right-[55%]" src="/img/roadmap_community.gif"/>
                    </div>

                    {/*<img className="w-full" src="/img/roadmap_title.png"/>*/}
                </div>

                {/* <div className="sm:flex justify-center gap-5">
        {constants.roadmap.steps.map((step, index) => (
          <div key={step.title} className="">
            <img className="w-full h-auto" src={step.image} />

            <div className={index === 0 ? '-mt-[25.5px]' : index === 2 ? 'mt-[26px]' : 'mt-5'}>
              <h6 className="pl-3 text-white text-sm text-center sm:text-left f-nh-black">
                {step.title}
              </h6>
              <ul>
                {step.lines.map((line, index) => (
                  <li key={index} className="text-white text-sm sm:text-xs mt-2">
                    &bull; {line}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div> */}
            </div>
        </div>
        </div>
    )
}
