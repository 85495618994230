import constants from '../constants';

export default function Footer() {
  return (
    <>
      <div className="relative z-10 w-full bg-[#FAF7EE]">
        <div className="absolute -top-6 left-0 right-0 hidden sm:flex flex-col sm:flex-row text-center justify-around gap-3 z-10">
          {/*<button*/}
          {/*  disabled*/}
          {/*  className="disabled:bg-gray-300 disabled:text-[#EBEBE4] disabled:border-gray-300 cursor-not-allowed relative hover-btn bg-od text-white uppercase overflow-hidden f-nh-black text-lg rounded py-2.5 px-6 sm:px-10"*/}
          {/*// href={constants.navbar.buyWithCard.href}*/}
          {/*// target="_blank"*/}
          {/*>*/}
          {/*  {constants.navbar.buyWithCard.title}*/}
          {/*</button>*/}

          {/*<a*/}
          {/*  className="relative hover-btn bg-od text-white uppercase overflow-hidden f-nh-black text-lg rounded py-2.5 px-6 sm:px-10"*/}
          {/*  href={constants.howToBuy.buyNowLink}*/}
          {/*  target="_blank"*/}
          {/*>*/}
          {/*  Buy on Solana*/}
          {/*</a>*/}
        </div>

        <div className="absolute -top-16 left-0 right-0">
          <div className="flex justify-center">
            <img className="h-32 w-32" src="/img/coin.gif" alt="" />
          </div>
        </div>

        <div className="pt-12" />

        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
          <div className="border-b border-[#C5BCAD] pb-4 mb-4">
            <div className="flex flex-col sm:flex-row space-y-5 sm:space-y-0 justify-between items-center">
              <div className="flex items-center gap-4">
                <img className="max-w-[31px] w-full h-auto" src="/img/logo_footer.png" alt="" />

                <div className="text-[15px] font-gt ">
                  {constants.footer.copyright}
                  <br />
                  <span className="">{constants.outOfGas.title}.</span>
                </div>
              </div>

              <div className="sm:ml-44 flex justify-center space-x-5">
                {constants.socialLinks.map((link, index) => (
                  <a key={index} href={link.href} className={!link.href ? 'opacity-40 pointer-events-none' : ''} target="_blank">
                    <img className={['h-5 transform hover:scale-105 transition grayscale filter brightness-0', link.customClass].join(' ')} src={link.icon} alt="" />
                  </a>
                ))}
              </div>

              <div className="text-right text-[15px] break-words break-all font-gt">
                {constants.footer.contact}
                <br />
                {constants.footer.contract}
              </div>
            </div>
          </div>
          <div className="text-[15px] font-gt text-[#C5BCAD]">
            <div>
              <strong className="mr-1">Disclaimer*</strong>
              {constants.footer.disclaimer}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
