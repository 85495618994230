import Title from "./Title";
import constants from "../constants";

export default function Tokenomics() {

    const getLeft = () => {
        if(constants.hero.right.progressBarProgress < 10) {
            return constants.hero.right.progressBarProgress;
        } else if(constants.hero.right.progressBarProgress < 25) {
            return constants.hero.right.progressBarProgress - 5;
        } else if(constants.hero.right.progressBarProgress < 50) {
            return constants.hero.right.progressBarProgress - 10;
        } else if(constants.hero.right.progressBarProgress < 70) {
            return constants.hero.right.progressBarProgress - 13;
        } else if(constants.hero.right.progressBarProgress < 85) {
            return constants.hero.right.progressBarProgress - 15;
        } else {
            return constants.hero.right.progressBarProgress - 15;
        }
    }

    return (
        <div
            id='tokenomics'
            className={`pt-16 relative pb-20 md:bg-[linear-gradient(90deg,rgba(255,255,255,1)_0%,rgba(255,254,252,1)_50%,rgba(254,159,93,1)_50%,rgba(255,170,90,1)_100%)]`}>
            <div className={`max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 grid grid-cols-1 md:grid-cols-2 gap-12 md:gap-0 `}>
                <div className={'flex items-center justify-center'}>
                    <div className={'max-w-[303px] w-full mx-auto'}>
                        <div className={`flex items-center justify-center`}>
                            <p className={`font-roboto-condenced uppercase mx-auto bg-od py-1 px-4 text-[14px] text-white`}>
                                {constants.hero.left.locked.title}
                            </p>
                        </div>
                        <Title
                            customClass='  mt-4 mb-4 !text-center font-cocogose-cb text-[60px] tracking-wider text-[#1D1D1D] pb-4'>
                            {constants.hero.title}
                        </Title>
                        <p className={`text-center text-[22px] font-founder-gt-cn`}>
                            <span
                                className={'font-founder-gt-cn-b'}>{constants.hero.left.supply.title}: </span> {constants.hero.left.supply.value}
                        </p>

                        <div className={`border border-od px-3 mt-8`}>
                            {constants.hero.left.supplementaryTitles.map((title, index) => (
                                <p key={title}
                                   className={`font-roboto-condenced uppercase font-[500] text-center border-b border-b-od py-2   last-of-type:border-b-0`}>
                                    {title}
                                </p>
                            ))}
                        </div>
                        <div className={'flex gap-3 mt-2 items-center'}>
                            <img src="/img/egg-cooking.png" alt="egg cooking"/>
                            <p className={`text-[17px] font-roboto-condenced mt-3`}>
                                {constants.hero.left.bottomText}
                            </p>
                        </div>
                    </div>
                </div>
                <div className={`bg-[linear-gradient(90deg,rgba(254,159,93,1)_0%,rgba(255,170,90,1)_100%)]`}>
                    <div className={`max-w-[380px] px-8 mx-auto pt-8`}>

                        <p className={`text-white mb-0 text-sm font-founder-gt`}>
                            {constants.hero.right.progressBarBottomText}
                        </p>
                        <div className={` flex items-center`}>
                            {/*         progress bar        */}
                            <div
                                className=" w-full relative p-[1px] h-[13px] bg-white flex items-center   rounded-full ">
                                <div
                                    className="bg-od  text-center border-2 border-od  h-[11px] rounded-full bg-[linear-gradient(90deg,#ffe85c_0%,#ffc32b_100%)]"
                                    style={{width: `${constants.hero.right.progressBarProgress}%`}}>
                                 </div>

                                {/*     bottom tool tip */}
                                <p
                                    style={{left: `${getLeft()}%`}}
                                    className={`absolute text-sm  font-gt flex items-center justify-center pt-1.5 px-2 rounded  left-0 bg-white   -bottom-[36px]`}>
                                    {constants.hero.right.progressBarProgress}%

                                    <div className={`h-2 w-2  rotate-45 bg-white absolute -top-1 left-1/2 rounded-[2px]  -translate-x-1/2`}/>
                                </p>
                            </div>
                        </div>

                    </div>
                    <div className={`flex items-center justify-center`}>
                        <img className={'mt-12'} src="/img/tokenomics-laptop.png" alt="tokenomics-laptop"/>
                    </div>
                </div>
                <img className={'absolute bottom-0 left-0 w-full h-auto'} src="/img/waves.svg" alt="waves"/>
            </div>


            {/*  <div className={'max-w-7xl mx-auto px-4 sm:px-6 lg:px-8  '}>*/}
            {/*    <div className="mt-10 text-center f-pp">*/}
            {/*        <Title>*/}
            {/*            {constants.hero.title}*/}
            {/*        </Title>*/}
            {/*        <div className="italic f-me text-left">*/}
            {/*            {constants.hero.description}*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*    <div id="tokenomics" className="mt-5 grid ">*/}
            {/*        <div className='grid md:grid-cols-2 gap-8 mb-8 '>*/}
            {/*            <div className="bg-ol flex flex-col border-4  border-odl rounded-3xl px-8 py-6">*/}
            {/*                <div className="flex flex-col space-y-5 sm:space-y-0 sm:flex-row justify-between">*/}
            {/*                    <div>*/}
            {/*                        <TitleSmall customClass="text-[18px] text-center sm:text-left">*/}
            {/*                            {constants.hero.left.supply.title}*/}
            {/*                        </TitleSmall>*/}

            {/*                        <p className="mt-3 text-sm text-center sm:text-left">*/}
            {/*                            {constants.hero.left.supply.value}*/}
            {/*                        </p>*/}
            {/*                    </div>*/}

            {/*                    <div>*/}
            {/*                        <TitleSmall customClass="text-[18px] text-center text-normal sm:text-left">*/}
            {/*                            {constants.hero.left.locked.title}*/}
            {/*                        </TitleSmall>*/}

            {/*                        <p className="mt-3 text-sm text-center">*/}
            {/*                            {constants.hero.left.locked.value}*/}
            {/*                        </p>*/}
            {/*                    </div>*/}
            {/*                </div>*/}

            {/*                <div className="mt-12 grid flex-1   gap-5">*/}
            {/*                    /!* <div>*/}
            {/*          <img src="/img/graph.png" alt="" />*/}
            {/*        </div> *!/*/}

            {/*                    <div className="mx-auto">*/}
            {/*                        {constants.hero.left.supplementaryTitles.map((title, index) => (*/}
            {/*                            <div className="pb-2">*/}
            {/*                                <TitleSmall key={index} customClass="text-lg">*/}
            {/*                                    {title ? (*/}
            {/*                                        <div>*/}
            {/*                                            &bull; <span className="ml-3">{title}</span>*/}
            {/*                                        </div>*/}
            {/*                                    ) : (*/}
            {/*                                        <br/>*/}
            {/*                                    )}*/}
            {/*                                </TitleSmall>*/}
            {/*                            </div>*/}
            {/*                        ))}*/}
            {/*                        <TitleSmall customClass="text-lg mt-4">*/}
            {/*                            {constants.hero.left.bottomText}*/}
            {/*                        </TitleSmall>*/}
            {/*                    </div>*/}
            {/*                </div>*/}

            {/*                /!* <div className="flex-col space-y-5 max-w-3xl mx-auto mt-4">*/}
            {/*        <div>*/}
            {/*          <TitleSmall customClass="text-[20px] text-center">*/}
            {/*            {constants.hero.right.first_title}*/}
            {/*          </TitleSmall>*/}

            {/*          <p className="mt-3 text-sm">*/}
            {/*            {constants.hero.right.first_content}*/}
            {/*          </p>*/}
            {/*        </div>*/}


            {/*      </div> *!/*/}
            {/*            </div>*/}
            {/*            <div>*/}
            {/*                <img className='' src={WorldRecordEgg} alt='World Record Egg'/>*/}
            {/*                <div className='pt-4'>*/}
            {/*                    /!* progress bar *!/*/}
            {/*                    <div class=" w-full bg-gray-400 dark:bg-gray-400 rounded-full overflow-hidden">*/}
            {/*                        <div class="bg-od w-[50%] text-center p-1"*/}
            {/*                             style={{width: `${constants.hero.right.progressBarProgress}%`}}>*/}
            {/*                            {constants.hero.right.progressBarProgress}%*/}

            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                    <p className="mt-3 text-sm text-center">*/}
            {/*                        {constants.hero.right.progressBarBottomText}*/}
            {/*                    </p>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}

            {/*    </div>*/}
            {/*</div>*/}
        </div>
    )
}
