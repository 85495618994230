
import { CopyContract } from "./components/CopyContract";
import { TrustedBy } from "./components/TrustedBy";

const constants = {
  withProgressBar: false,
  progressBarProgress: 100,
  socialLinks: [
    {
      icon: '/img/social-icons/twitter.svg',
      href: 'https://twitter.com/justaneggcoin',
    },
    {
      icon: '/img/social-icons/telegram.svg',
      href: 'https://t.me/JustAnEggSol',
    },
    {
      icon: '/img/social-icons/tiktok.svg',
      href: 'https://www.tiktok.com/@world_record_egg',
    },
    {
      icon: '/img/social-icons/instagram.svg',
      href: 'https://www.instagram.com/world_record_egg/',
    },
    {
      icon: '/img/social-icons/dxscreen.svg',
      href: 'https://dexscreener.com/solana/wo1zgt8rfrYpvdVi4nidoj1SYfcR4pQx69bmNv2JLhQ?maker=76KRS8Tr913sZVucLNRTm8GvETnRpEoXLkd8SuprLSNm',
    },
    {
      icon: '/img/social-icons/dx-tools.svg',
      href: 'https://www.dextools.io/app/en/solana/pair-explorer/wo1zgt8rfrYpvdVi4nidoj1SYfcR4pQx69bmNv2JLhQ?t=1711235593940',
    },
    {
      icon: '/img/social-icons/coin-market-cap.svg',
      href: 'https://coinmarketcap.com/currencies/justanegg-sol/',
    },
    {
      icon: '/img/social-icons/coin-gecko.svg',
      href: 'https://www.coingecko.com/en/coins/justanegg-2',
    },
    {
      icon: '/img/social-icons/radium.svg',
      href: 'https://jup.ag/swap/SOL-EGG_wo1zgt8rfrYpvdVi4nidoj1SYfcR4pQx69bmNv2JLhQ',
      // customClass: ' h-5 w-5'
    },
  ],
  navbar: {
    links: [
      {
        title: 'Tokenomics',
        href: '#tokenomics',
      },
      {
        title: 'About',
        href: '#about',
      },
      {
        title: 'Roadmap',
        href: '#roadmap',
      },
      {
        title: 'How To Buy',
        href: '#how-to-buy',
      },
      // {
      //   title: 'FAQ',
      //   href: '#faq',
      // },
      {
        title: 'Shop',
        href: '#',
        links: [
          {
            title: 'RUBIKS',
            href: '#'
          },
          {
            title: 'Accessories',
            href: '#'
          },
          {
            title: 'Hoodies',
            href: '#'
          },
          {
            title: 'Hats',
            href: '#'
          },
          {
            title: 'Eggs',
            href: '#'
          },
        ],
      }
      // {
      //   title: 'Contact',
      //   href: '#contact',
      // }
    ],
    buyNowLabel: 'BUY ON JUPITER',
    buyWithCard: {
      title: 'BUY WITH CARD',
      // href: 'https://pay.c14.money/?targetAssetId=ebb41072-a8c6-415f-aef0-a5c11a143833'
      href: 'https://jup.ag/swap/SOL-EGG_wo1zgt8rfrYpvdVi4nidoj1SYfcR4pQx69bmNv2JLhQ'
    }
  },
  uniswap: {
    description: ''
  },
  hero: {
    title: 'TOKENOMICS',
    description: (
      <div>
      </div>
    ),
    left: {
      supply: {
        title: 'Token Supply',
        value: '399,399,399 tokens',
      },
      locked: {
        title: 'This is a community project',
        value: '',
      },
      supplementaryTitles: [
        '93.1% CIRCULATING SUPPLY',
        // '',
        // '',
        // '',
        '6.9% AIRDROP',
        'NO PRESALE',
        'CONTRACT RENOUNCED',
        'LIQUIDITY POOL BURNED',
        'MINT/FREEEZE REVOKED',
        '0% TAXES',
      ],
      bottomText: (
        <div>
          Wait, the <s className="strikethrough">dev</s> egg is cooking
        </div>
      )
    },
    right: {
      // first_title: 'Wait, the <s>dev</s> egg is cooking',
      first_content: (
        <div>
        </div>
      ),
      progressBarProgress: 100,
      progressBarBottomText: '$1,000,000 / $1,000,000'
    }
  },
  headlines: ['Just An Egg','World Record EGG','60M likes','WEB2 & WEB3 Meme'],
  imageGallery: {
    blackText: 'INSIDE THE',
    redText: 'HEN HOUSE',
  },
  about: {
    title: {
      textRed: 'INTRODUCING $EGG -',
      textBlack: 'literally just an egg'
    },
    content: (
      <div className={'font-founder-gt text-[18px]'}>
       Welcome to JustAnEgg, the only cryptocurrency project that’s egg-ceptionally good!
        <br /><br />
       Imagine if your breakfast winked back and turned into crypto. JustAnEgg!<br /> Not your average egg pun factory; we're here to fry up the crypto game and poach the top spot among meme coins. Serious security with a side of laughs. Join us, and let’s make web culture egg-straordinary!
        <br /><br />
        Our goal is to reach the meme coins top 10 by setting high standards, building meaningful partnerships as well as a strong community of supporters.
        <br /><br />
     Join us, and let’s make web culture egg-straordinary!
      </div>
    )
  },
  roadmap: {
    title: (
      <div>
        Roadmap? bro, i'm just an egg.. or not
        <br /><br />
        Buckle up, grab your spatulas, and let's hatch our way to success!
      </div>
    ),
    steps: [
      {
        image: '/img/roadmap_step1.gif',
        title: 'Phase 1: THE EGG IS LAID',
        lines: [
          'Website & socials launch',
          '$EGG Token Creation and smart contract development (audited)',
          'Dextool launch',
          'Listing on CoinGecko & Coinmarketcap',
          'Renounce ownership of the Smart Contract',
          '1,000 Holders'
        ]
      },
      {
        image: '/img/roadmap_step2.gif',
        title: 'Phase 2: SUNNY SIDE UP ',
        lines: [
          'Achieving market penetration',
          'Building brand awareness',
          'Targeting new audiences with social media activations',
          'Building a community',
          '10,000 Holders | $100M Market Cap'
        ]
      },
      {
        image: '/img/roadmap_step3.gif',
        title: 'Phase 3: THE EGG-SPANSION',
        lines: [
          'Buying out @world_record_egg page',
          'Egg Game: the egg that gives you tokens!',
          'The egg-spectations are high and anything can hatch!',
          '100,000 Holders | $1B Market Cap',
          'Top 10 Meme Coin'
        ]
      }
    ]
  },
  outOfGas: {
    title: 'Egg-citing taste',
    description: 'Egg Swap will be our one of a kind lending protocol that loans ethereum for users to make the swap!'
  },
  howToBuy: {
    title: 'HOW TO BUY?',
    steps: [
      {
        title: 'Step 1',
        content: 'Set up a Phantom Wallet, and download it onto your device.',
      },
      {
        title: 'Step 2',
        content: 'Purchase Solana (SOL) on a cryptocurrency exchange and transfer it to your wallet.',
      },
      {
        title: 'Step 3',
        content: <div>
          Connect your wallet to a decentralized exchange like Jupiter <a target={'_blank'} className={'no-underline'} href="https://jup.ag/swap/SOL-EGG_wo1zgt8rfrYpvdVi4nidoj1SYfcR4pQx69bmNv2JLhQ">(www.jup.ag)</a> or Raydium.
        </div>,
      },
      {
        title: 'Step 4',
        content: <>

        Select $EGG Token (JustAnEgg) as the desired token and enter the amount you wish to purchase. You can also find it by using the contract address:  <CopyContract  contract={'wo1zgt8rfrYpvdVi4nidoj1SYfcR4pQx69bmNv2JLhQ'}/>
        </>
      },
      {
        title: 'Step 5',
        content: 'Double-check the transaction details, confirm the swap and you’re all set!'
      }
    ],
    buyNowLink: 'https://jup.ag/swap/SOL-EGG_wo1zgt8rfrYpvdVi4nidoj1SYfcR4pQx69bmNv2JLhQ'
  },
  faq: {
    title: 'FAQ',
    questions: [
      {
        question: 'What is $EGG token?',
        answer: '$EGG is an innovative meme token that brings together the playful spirit of meme culture and the potential of blockchain technology. As a Solana token, $EGG aims to go beyond traditional meme tokens by fostering community, promoting cultural connections, and creating value for its supporters.'
      },
      {
        question: 'What makes JustAnEgg unique?',
        answer: 'What makes Justanegg unique is its focus on promoting and celebrating the humble egg through a coinmeme project. We combine the fun and engaging world of memes with the power of blockchain technology. Additionally, Justanegg offers interesting tokenomics which could set it apart from other coinmeme projects'
      },
      {
        question: 'When is the launch? On which blockchain network?',
        answer: '$EGG, a token built on Solana, will launch on Sunday, March 10th, utilizing the reliable and secure infrastructure of a top blockchain platform.'
      },
      {
        question: 'How do I buy JustAnEgg token? Where should i store my coins?',
        answer: (
          <div>
            1. Set up a Phantom wallet, and download it onto your device.
            <br />
            2. Purchase Solana (SOL) on a cryptocurrency exchange and transfer it to your wallet.
            <br />
            3. Connect your wallet to a decentralized exchange like Jupiter (https://jup.ag/) or Raydium.
            <br />
            4. Select $EGG Token (JustAnEgg) as the desired token and enter the amount you wish to purchase.<br /> You can also find it by using the contract address:<br /> wo1zgt8rfrYpvdVi4nidoj1SYfcR4pQx69bmNv2JLhQ
            <br />
            5. Double-check the transaction details,  confirm the swap and you’re all set!
            <br />
            6. JustanEgg coins must be stored on a wallet that supports SOL tokens such as Phantom.
          </div>
        )
      },
      {
        question: 'How does the $EGG token intend to increase in value and expand its reach?',
        answer: 'The $EGG token plans to grow and create value by continuously innovating and improving its features and utilities, increasing adoption through strategic partnerships and marketing efforts, and building a strong community of supporters and contributors. The project aims to offer unique and exciting opportunities for token holders, as well as contribute to the broader ecosystem of decentralized finance and blockchain technology.'
      }
    ]
  },
  TrustedBy: {
    poloniex: 'https://poloniex.com/trade/JUSTANEGG_USDT/?type=spot',
    ascendex: 'https://ascendex.com/en-us/cashtrade-spottrading/usdt/egg'
  },
  footer: {
    copyright: '© 2024 justanegg. | $EGG',
    contact: 'Contact: eggshell@justanegg.com',
    contract: 'Contract: wo1zgt8rfrYpvdVi4nidoj1SYfcR4pQx69bmNv2JLhQ',
    disclaimer: `$EGG is a memecoin and has no utility. Don't risk money you are afraid of losing. The price may go up or it may go down. We are not responsible for the price of the token.`
  }
}

export default constants;
