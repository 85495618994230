import music from '../assets/EGG_SONG.mp3';
import { useState, useEffect } from 'react';
import { HiSpeakerXMark, HiSpeakerWave   } from "react-icons/hi2";

const useAudio = url => {
  const [audio] = useState(new Audio(url));
  const [playing, setPlaying] = useState(false);

  const toggle = () => setPlaying(!playing);

  useEffect(() => {
    const handleMouseMove = () => {
      toggle();
      document.body.removeEventListener("click", handleMouseMove);
    };

    document.body.addEventListener("click", handleMouseMove);

    return () => {
      document.body.removeEventListener("click", handleMouseMove);
    };
  },[]);

  useEffect(() => {
      playing ? audio.play() : audio.pause();
    },
    [playing]
  );

  useEffect(() => {
    audio.volume = 0.5;
    audio.addEventListener('ended', () => setPlaying(false));
    return () => {
      audio.removeEventListener('ended', () => setPlaying(false));
    };
  }, []);

  return [playing, toggle];
};

export default function Sound() {
  const [playing, toggle] = useAudio(music);

  return (
    <div>
      <button onClick={toggle} className="cursor-pointer mt-1.5">
        {playing ? (
            <>
          {/*<img className="h-4 w-auto" src="/img/sound_on.png" alt=""/>*/}
              <HiSpeakerWave size={20} color={'#F6A45E'} />
            </>
        ) : (
            <>
          {/*<img className="h-4 w-auto" src="/img/sound_off.png" alt=""/>*/}
              <HiSpeakerXMark size={20} color={'#F6A45E'}/>
            </>
        )}
      </button>
    </div>
  )
}
