import React from 'react';
import Marquee from "react-fast-marquee";
import constants from "../constants";

export const Headlines = () => {
    return (
        <div className={`bg-[#F6A45E] py-1 mt-16`}>
            <Marquee autoFill style={{gap: '1rem'}} className={' '}>
                {constants.headlines.map(val => (
                    <div key={val} className={`mx-4 uppercase text-base text-white font-roboto-condenced`}>
                        {val}
                    </div>
                ))}
            </Marquee>

        </div>
    );
};
